jQuery(document).ready(function ($) {

  // Slides
  var swiper = new Swiper('.js-slider-lines', {
    slidesPerView: 'auto',
    centeredSlides: true,
    paginationClickable: true,
    watchOverflow: true,
    autoplay: {
      delay: 8000,
    },
    pagination: {
      el: '.js-slider-lines .swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
    navigation: {
      nextEl: ".js-slider-lines .swiper-button-next",
      prevEl: ".js-slider-lines .swiper-button-prev",
    }
  });

  // Header
  $(window).on('scroll', function() {
    const $html = $('html'),
          $this = $(this);
    
    if ($this.scrollTop() != 0) {
      $html.addClass('is-active-header')
    } else {
      $html.removeClass('is-active-header')
    }
  });

  // Remove action
  $('.js-no-action > a').on('click', function(e) {
    e.preventDefault();
  });

  // Menu mobile
  $('.js-menu-mobile').on('click', function(e) {
    const $html = $('html');

    $html.toggleClass('is-active-menu');
  });

  // Play video html
  $('.js-video-html').on('click', function(el) {
    el.preventDefault();
    const $this = $(this);

    $this.toggleClass('is-playing');
    $this.prop('controls', true);
    $this[0].paused ? $this.trigger('play') : $this.trigger('pause');
  });

  // Animeted on scroll
  $(window).on('scroll', function() {
    const winHeight = $(this).innerHeight(),
          winScroll = $(this).scrollTop() + winHeight;

    $('.js-animated').each(function() {
      const $this = $(this),
            elTop = $this.offset().top;

      if(winScroll >= elTop + winHeight / 3) {
        $this.addClass('is-visible');
      }
    });
  });

  // Animated start
  function start() {
    $('.js-animated-start').addClass('is-visible');
  }

  start();

  $('#category-dropdown').on('change', function() {
    var selectedCategory = $(this).val();
    if (selectedCategory) {
        window.location.href = selectedCategory;
    }
  });

  // MASKED INPUT
  $(".js-data").mask("99/99/9999");
  $(".js-cpf").mask("999.999.999-99");
  $(".js-cep").mask("99999-999");
  $(".js-cnpj").mask("99.999.999/9999-99");
  $('.js-phone').focusout(function(){
    var phone, element;
    element = $(this);
    element.unmask();
    phone = element.val().replace(/\D/g, '');
    if(phone.length > 10) {
      element.mask("(99) 99999-999?9");
    } else {
      element.mask("(99) 9999-9999?9");
    }
  }).trigger('focusout');

});